import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

interface BgProps {
  backgroundImage: string
  isActive?: boolean
}

export const Container = styled.div`
  ${fontStyle}
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  border: 4px solid #FFFFFF;
  border-radius: 12px;
  margin: .8%;
`

export const Question = styled.div``

export const Answer = styled.div``
