import { Contract, ethers } from 'ethers'
import { useMemo } from 'react'
import { getContract, getContractWithSimpleProvider } from 'Utils/web3Helper'
import { useActiveWeb3React } from './useActiveWeb3React'
import { Qnekt_ERC20 } from "Constants/Network";
import QRC20_ABI from "Constants/QRC20_ABI.json";

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true,
  withSimpleProvider = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId!]
    if (!address) return null
    try {
      if (withSimpleProvider) {
        if (!chainId) return null
        return getContractWithSimpleProvider(address, ABI, chainId)
      }
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error: any) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSimpleProvider, withSignerIfPossible, account]) as T
  
}

export const useGetQRCContract = (withSigner = false, withSimpleProvider = true) => {
  return useContract(ethers.utils.getAddress(Qnekt_ERC20), QRC20_ABI, withSigner, withSimpleProvider)
}