import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

const scoreFontStyle = css`
  ${fontStyle}
  font-weight: 800;
  font-size: 24px;
  line-height: 26px;
  margin-left: 4%;

  @media (max-width: 1355px) {
    font-size: 22px;
    line-height: 24px;
  }

  @media (max-width: 1215px) {
    font-size: 21px;
    line-height: 24px;
  }

  @media (max-width: 1075px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 20px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #CF4CC7;
  padding: 2% 0;
  cursor: pointer;
`

export const Name = styled.span`
  ${scoreFontStyle}
  margin-left: 2%;
  white-space: nowrap;
`

export const TodayScore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 3%;
  ${scoreFontStyle}
`