import React from 'react'
import {
  Container
} from './ColorPaletteTile.components'
import { Color } from 'Types/color'

interface Props {
  color: Color,
  onClick: (code: string) => void
}

const ColorPaletteTile: React.FC<Props> = ({ color, onClick }) => {
  return (
    <Container colorCode={color.code} size={75} onClick={() => onClick(color.code)}>
    </Container>
  )
}

export default ColorPaletteTile
