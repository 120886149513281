import styled, { css } from 'styled-components'
import bg from '../assets/images/bg.webp'

export const widthStyle = css`
  width: 75%;
  margin: 0 auto;
  max-width: 1111px;

  @media (max-width: 765px) {
    width: 90%;
  }
`

export const fontStyle = css`
  font-family: Manrope;
  font-style: normal;
  color: white;
`

export const Container = styled.div`
  background: #1F0E6B;
`

export const Header = styled.header`
  text-align: center;
  background: linear-gradient(180deg, #5B29E1 0%, #963CD4 100%);
`

export const HeaderContainer = styled.div`
  ${widthStyle}
  padding: 50px 0 80px;
`

export const InstructionContainer = styled.div`
  ${widthStyle}
  padding: 50px 0 80px;
`

export const BgWrapper = styled.div`
  background: url(${bg}) no-repeat;
  background-size: cover;
`

export const Logo = styled.img`
  width: 240px;
  height: 80px;
  margin-top: 90px;
  margin-bottom: 100px;

  @media (max-width: 1355px) {
    
  }

  @media (max-width: 1215px) {
    width: 210px;
    height: 70px;
    margin-top: 80px;
    margin-bottom: 90px;
  }

  @media (max-width: 1075px) {
    width: 190px;
    height: 65px;
    margin-top: 75px;
    margin-bottom: 85px;
  }

  @media (max-width: 900px) {
    width: 160px;
    height: 55px;
    margin-top: 60px;
    margin-bottom: 70px;
  }
`

export const Title = styled.div`
  ${fontStyle}
  font-weight: bold;
  font-size: 65px;
  line-height: 70px;
  text-align: center;

  @media (max-width: 1355px) {
  }

  @media (max-width: 1215px) {
    font-size: 56px;
  }

  @media (max-width: 1075px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 45px;
  }
`

export const Footer = styled.footer`
  min-height: 310px;
  background: linear-gradient(180deg, #361792 12.18%, #13096A 58.38%);
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: 1075px) {
    min-height: 270px;
  }

  @media (max-width: 900px) {
    min-height: 210px;
  }
`

export const FooterWidth = styled.div`
  ${widthStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FooterLogo = styled.img`
  width: 180px;
  height: 90px;

  @media (max-width: 1355px) {
  }

  @media (max-width: 1215px) {
    width: 160px;
    height: 55px;
  }

  @media (max-width: 1075px) {
    width: 140px;
    height: 50px;
  }

  @media (max-width: 900px) {
    width: 120px;
    height: 40px;
  }
`

export const FaqButton = styled.div`
  ${fontStyle}
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-decoration-line: underline;
  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 20px;
  }

  @media (max-width: 1215px) {
  }

  @media (max-width: 1075px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`