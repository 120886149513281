import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmptyWalletTile from './EmptyWalletTile/EmptyWalletTile'
import {
  Container,
  UsersList
} from './EmptyWallets.components'
import { LeaderboardOrderedUser } from 'Types/user'

const EmptyWallets: React.FC<{users: LeaderboardOrderedUser[]; gameId: string}> = ({users, gameId}) => {
  const { t } = useTranslation()

  const [emptyUsers, setEmptyUsers] = useState<LeaderboardOrderedUser[] | undefined>()
  
  useEffect(() => {
    const emptyList = users?.filter((item) => item.score === 100)
    setEmptyUsers([...emptyList])
  }, [users])
  return (
    <Container>
      {t('emptyWallets')}
      <UsersList>
        {emptyUsers?.map((user, index) => 
          <EmptyWalletTile {...{...user}} key={index}/>
        )}
      </UsersList>
    </Container>
  )
}

export default EmptyWallets
