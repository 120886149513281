import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  ColorFont,
  Container, DetailContentBox, DetailListItem, DownloadLink, MainContentBox, WelcomeTitle,
} from './Instruction.components'
import { Game } from 'Types/game'

const Instruction: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Container>
      <WelcomeTitle>{'Welcome to QNekt Beta Test'}</WelcomeTitle>
      <MainContentBox>
        <span>
          {`Thank you for joining our QNekt Beta Test.`}
        </span>
        <span>
          {`We will be testing the Qtum QNekt Web3 wallet in form of three fun games.`}
        </span>
        <span>
          {`Once you enter the game, the rules and awards will be displayed at the top of the page for each game.`}
        </span>
        <span>
          <ColorFont color={'cyan'}>{`Game one, `}</ColorFont><strong>{`“Get Poor or Lose Trying”`}</strong> {`starts on DATE HERE and ends on DATE HERE.`}
        </span>
        <span>
          <ColorFont color={'cyan'}>{`Game two `}</ColorFont><strong>{`“Fiat Your Enemy”`}</strong> {` starts on DATE HERE and ends on DATE HERE.`}
        </span>
        <span>
          <ColorFont color={'cyan'}>{`Game three, `}</ColorFont><strong>{`“Bansky”`}</strong> {` starts on DATE HERE and ends on DATE HERE.`}
        </span>
        <span>{`On each game, there is a bug report option.\n We ask that anyone who finds any issues with QNekt to please fill out the bug report and hit submit.`}</span>
        <span>{`We are looking for any technical issues, design issues, incorrect grammar, links, functionality, etc.`}</span>
        <span>{`If your issue is valid, you will receive an award of X Qtum at the end of the game. (We are not looking for issues with the website, only QNekt)`}</span>
      </MainContentBox>
      <DetailContentBox>
        <ColorFont color={'cyan'} style={{ marginLeft: '1em' }}>{`To properly enter please follow the steps below:`}</ColorFont>
        <ol>
          <li value={1}>
            <DetailListItem>
              <span>{`If you do not have a QNekt web3 wallet, please download the wallet here:`}</span>
              <DownloadLink href='https://github.com/earlgreytech/metamask-extension/releases/tag/v10.18.4.QTUM-alpha-5'>{'Download qnekt here'}</DownloadLink>
            </DetailListItem>
          </li>
          <li>
            <DetailListItem>
              <span>{`If you are not already a member, join our Qtum Discord Server to register for this event by following the link below:`}</span>
              <DownloadLink href='https://discord.gg/SFvznQgf8k' target={'_blank'}>{'https://discord.gg/SFvznQgf8k'}</DownloadLink>
            </DetailListItem>
          </li>
          <li>
            <DetailListItem>
              <span>{`Once you join our Discord Server, paste your QNekt QTUM Testnet (HEX) address in the #qnekt-beta-test channel`}</span>
              <span>{`(QNekt issues HEX addresses by default. Hex addresses start with 0x. Make sure to paste the HEX address into the Discord channel or you will not be entered. You can change from QTUM or HEX address in the Settings menu.)`}</span>
            </DetailListItem>
          </li>
          <li>
            <DetailListItem>
              <span>{`Take a screenshot of your QNekt wallet open and Tweet proof with #QNekt, then post the link of your Tweet into the qnekt-proof channel in the Qtum Discord server.`}</span>
              <span>{`(Don’t worry about obtaining testnet coins from the faucet. Testnet coins will be sent to your QNekt wallet within x hours.)`}</span>
            </DetailListItem>
          </li>
        </ol>
      </DetailContentBox>
      <MainContentBox style={{marginTop: '0', marginBottom: '1%'}}>
        <span>{`After following these steps, you are entered, and you can proceed to Game 1 “Get Poor or Lose Trying”`}</span>
      </MainContentBox>
    </Container>
  )
}

export default Instruction
