import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
// @ts-ignore
import rootReducer from 'Store/Reducers'
import rootSaga from 'Store/Sagas'


const configStore = () => {
    const middleware = []

    const sagaMiddleware = createSagaMiddleware()
    middleware.push(sagaMiddleware)

    let enhancer = applyMiddleware(...middleware)


    const store = createStore(rootReducer, enhancer)

    sagaMiddleware.run(rootSaga)

    return { store }
}

export default configStore