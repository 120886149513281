import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { ToastContainer } from 'react-toastify'
import App from './Routes'
import reportWebVitals from './reportWebVitals'
import { Web3ReactProvider } from '@qweb3-react-dev/core'
import { getLibrary } from 'Utils/web3Helper'
import { useEagerConnect } from 'hooks'

const GlobalHooks = () => {
  useEagerConnect()
  return null
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <GlobalHooks />
      <ToastContainer
        position='top-center'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored' />
      <App />
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
