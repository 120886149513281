import { JsonRpcSigner, StaticJsonRpcProvider, Web3Provider } from '@ethersproject/providers'
import { QTUM_MAINNET_RPC_URL, QTUM_REGTEST_RPC_URL, QTUM_TESTNET_RPC_URL } from 'Constants/Network'
import { BigNumber, Contract, ethers } from 'ethers'
import { QtumProvider as Provider } from 'qtum-ethers-wrapper';

export const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

export const getSimpleRPCProvider = (chainId: number | undefined) => {
  if (chainId === undefined) return null
  return new StaticJsonRpcProvider(chainId === 8888 ? QTUM_MAINNET_RPC_URL : QTUM_TESTNET_RPC_URL)
}

export const getRPCProvider = (chainId: number) => {
  return new Provider(chainId === 81 ? QTUM_MAINNET_RPC_URL : chainId === 8889 ? QTUM_TESTNET_RPC_URL : QTUM_REGTEST_RPC_URL, )
}

// account is optional
export const getProviderOrSigner = (library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner => {
  return account ? getSigner(library, account) : library
}

export const getSigner = (library: Web3Provider, account: string): JsonRpcSigner => {
  return library.getSigner(account).connectUnchecked()
}

export const getBalanceNumber = (amount: BigNumber, decimal = 18, displayDecimals = 2) => {
  const formattedString = ethers.utils.formatUnits(amount, decimal)
  return (+formattedString).toFixed(displayDecimals)
}

export const getNumberFromBigNumber = (amount: BigNumber, decimal = 18) => {
  return Number(ethers.utils.formatUnits(amount, decimal))
}

export const getBigNumberFromString = (amount: string, decimal = 18) => {
  return ethers.utils.parseUnits(amount, decimal)
}

export const isAddress = (value: any): string | false => {
  try {
    return ethers.utils.getAddress(value)
  } catch {
    return false
  }
}

// account is optional
export const getContract = (address: string, ABI: any, library: Web3Provider, account?: string): Contract => {
  if (!isAddress(address) || address === ethers.constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export const getContractWithSimpleProvider = (address: string, ABI: any, chainId: number) => {
  if (!isAddress(address) || address === ethers.constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  const provider = getSimpleRPCProvider(chainId)
  if (!provider) throw Error('Cant Get Provider Correctly, Please check your Network status')

  return new Contract(address, ABI, provider)
}

export const shortenAddress = (address: string, chars = 4): string => {
  const parsed = isAddress(address)
  if (!parsed) {
    // throw Error(`Invalid 'address' parameter '${address}'.`)
    return ''
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

export const getPaddedHexStrFromBN = (bn: BigNumber) => {
  const hexStr = ethers.utils.hexlify(bn);
  return ethers.utils.hexZeroPad(hexStr, 32)
}
