import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ParticipantTile from './ParticipantTile/ParticipantTile'
import Modal from 'react-modal';
import {
  Container,
  UsersGrid,
  ModalContent, ModalTitle, ModalDescription, ModalTargetInfo, ModalSpan, ModalSignerInfo, ModalAmountBox, ModalInput, ModalSendOption, ModalButton, ModalSendOptionBox 
} from './Participants.components'
import { LeaderboardUser, User } from 'Types/user'
import { useUserInfo, useSendCoins, useActiveWeb3React, TargetAccount } from 'hooks'
import { useParams } from 'react-router-dom'
import { getBalanceNumber } from 'Utils/web3Helper'
import { Oval } from 'react-loader-spinner';

const modalStyles = {
  overlay: {
    backgroundColor: '#150755b0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '50%',
    width: '50%'
  },
};

const Participants: React.FC<{users: LeaderboardUser[]}> = ({users}) => {
  const { t } = useTranslation()
  let { gameId } = useParams()
  const { account } = useActiveWeb3React()
  const { qtumBalance, qrcBalance, handleQtumBalance, handleQRC20Balance } = useUserInfo()
  const { isLoading, targetAccount, sendAmount, sendModalOpen, toggleModal, handleOpenModal, handleSetAmountWithOption, handleSetAmountManually, handleQtumSendTx, handleQRCSendTx } = useSendCoins()

  const handleSendTx = () => {
    if (gameId === 'game001') {
      handleQtumSendTx()
    } else {
      handleQRCSendTx()
    }
  }

  useEffect(() => {
    if (!account) return
    handleQtumBalance(account)
    handleQRC20Balance(account)
  }, [account, handleQtumBalance, handleQRC20Balance])

  return (
    <Container>
      {t('participants')}
      <UsersGrid>
        {users.map((user, index) =>
          <ParticipantTile user={user} key={index} handleOpenModal={handleOpenModal} />
        )}
      </UsersGrid>
      <Modal
        isOpen={sendModalOpen}
        onRequestClose={toggleModal(false)}
        style={modalStyles}
      >
        <ModalContent>
          <ModalTitle>{t('sendCoin')}</ModalTitle>
          <ModalDescription>
            <ModalTargetInfo>
              <ModalSpan>{'To'}</ModalSpan>
              <ModalSpan>{targetAccount?.name}</ModalSpan>
            </ModalTargetInfo>
            <ModalSignerInfo>
              <ModalSpan>{'Your wallet'}</ModalSpan>
              {gameId === 'game001' ? (
                <ModalSpan>{qtumBalance !== undefined ? getBalanceNumber(qtumBalance!) : '0.00'}{' QTUM'}</ModalSpan>
              ) : (
                <ModalSpan>{qrcBalance !== undefined ? getBalanceNumber(qrcBalance!) : '0.00'}{' QRC'}</ModalSpan>
              )}
            </ModalSignerInfo>
          </ModalDescription>
          <ModalAmountBox>
            <ModalInput id={'message'} name={'message'} placeholder={t('sendDescription')} value={sendAmount} onChange={(e) => handleSetAmountManually(e)} />
            <ModalSendOptionBox>
              <ModalSendOption onClick={() => handleSetAmountWithOption('50.00')}>{'50'}</ModalSendOption>
              <ModalSendOption onClick={() => handleSetAmountWithOption('100.00')}>{'100'}</ModalSendOption>
              <ModalSendOption onClick={() => handleSetAmountWithOption(gameId === 'game001' ? getBalanceNumber(qtumBalance!) : getBalanceNumber(qrcBalance!), true)}>{'Max'}</ModalSendOption>
            </ModalSendOptionBox>
          </ModalAmountBox>
          {isLoading ? (
            <ModalButton type={'button'}>
              <Oval 
                height={20}
                width={20}
                color="#5B29E1"
                wrapperStyle={{margin: 'auto', justifyContent: 'center'}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#FFFFFF"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </ModalButton>
          ) : (
            <ModalButton type={'button'} onClick={() => handleSendTx()}>{t('send')}</ModalButton>
          )}
        </ModalContent>
      </Modal>

    </Container>
  )
}

export default Participants
