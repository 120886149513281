import { all, fork } from 'redux-saga/effects'
import artService from './ArtService'
import startup from './Startup'

const rootSaga = function* root() {
  yield all([
    fork(startup),
    fork(artService)
  ])
}

export default rootSaga