import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Name,
  TodayScore
} from './GameSubHeader.components'
import { LeaderboardUser } from 'Types/user'

const GameSubHeader: React.FC<{signerData: LeaderboardUser | undefined, gameId: string}> = ({signerData, gameId}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <TodayScore>
        {gameId === 'game001' && (
          <>
            <Name>
              {t('signerQtumScore')}: 
            </Name>
            {signerData?.qtum_score && 
              <Name>
                {Number(signerData?.qtum_score)?.toFixed(2)}
              </Name>
            }
          </>
        )}
        {gameId === 'game002' && (
          <>
            <Name>
              {t('signerQrcScore')}: 
            </Name>
            {signerData?.qrc_score && 
              <Name>
                {Number(signerData?.qrc_score)?.toFixed(2)}
              </Name>
            }
          </>
        )}
      </TodayScore>
    </Container>
  )
}

export default GameSubHeader
