import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

interface ContentProps {
  backgroundImage?: string
  color?: string
  isActive?: boolean
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  
  border: 4px solid #FFFFFF;
  border-radius: 12px;
  margin: .8%;
  
  background-color: #0645F7;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`

export const WelcomeTitle = styled.h5`
  ${fontStyle}
  text-align: left;
  width: 100%;
  padding-left: 3%;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0.5%;
  @media (max-width: 1355px) {
    font-size: 22px;
    line-height: 32px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }

`

export const MainContentBox = styled.div`
  ${fontStyle}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  font-size: 20px;
  line-height: 24px;
  padding: 1% 3%;
  margin-top: 1%;
  width: 100%;
  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 24px;
  }
  & p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  & span {
    line-height: 28px;
  }
`

export const ColorFont = styled.span`
  color: ${({ color }: ContentProps) => color};
  font-weight: bold;
`

export const DetailContentBox = styled.div`
  ${fontStyle}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  font-size: 24px;
  padding: 1% 3%;
  margin-top: 1%;
  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 24px;
  }
`

export const DetailListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 20px;
  line-height: 28px;
  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 24px;
  }
`

export const DownloadLink = styled.a`
  ${fontStyle}
  color: #FFF;
`