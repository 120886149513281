import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React } from '@qweb3-react-dev/core'
import { Web3ReactContextInterface } from '@qweb3-react-dev/core/dist/types'
import { QTUM_TESTNET_NETWORK_ID } from 'Constants/Network'
import { useEffect, useRef, useState } from 'react'
import { getSimpleRPCProvider } from 'Utils/web3Helper'
/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
export const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, ...web3React } = useWeb3React()
  const refEth = useRef(library)
  const [provider, setProvider] = useState(library || getSimpleRPCProvider(chainId))
  useEffect(() => {
    if (library !== refEth.current) {
      setProvider(library || getSimpleRPCProvider(chainId))
      refEth.current = library
    }
  }, [chainId, library])
  return { library: provider, chainId: chainId ?? Number(QTUM_TESTNET_NETWORK_ID), ...web3React }
}