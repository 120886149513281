import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { useTranslation } from 'react-i18next'
import { shortenAddress } from 'Utils/web3Helper'
import {
  Container,
  ConnectButton,
  ConnectedAccount,
  DiscordConnectButton
} from './ConnectWalletButton.components'
import { useWalletConnect } from 'hooks'
import { QNEKT_CONFIG } from 'Constants/Network'
import { InjectedConnector } from '@qweb3-react-dev/injected-connector'
import { LeaderboardUser } from 'Types/user'

export const ConnectWalletButton: React.FC<{signerData: LeaderboardUser | undefined}> = ({signerData}) => {
  const { t } = useTranslation()
  const { account, connector, chainId, error } = useActiveWeb3React()
  const { connect } = useWalletConnect()

  const handleConnectWallet = () => {
    connect(QNEKT_CONFIG.connector as InjectedConnector, 'injected')
  }

  return (
    <Container>
      {account === undefined ? (
        <ConnectButton onClick={() => handleConnectWallet()}>{t('connectWallet')}</ConnectButton>
      ) : (
        <>
          {!signerData?.qnekt_address && 
            <DiscordConnectButton href={`https://discord.gg/CuGJTkxf`} target={'_blank'}>{`Discord Join`}</DiscordConnectButton>
          }
          <ConnectedAccount>{shortenAddress(account!)}</ConnectedAccount>
        </>
      )}
    </Container>
  )
}
