import { mainAPI, artServiceAPI } from "./axios";

const fortmatResponse = (res: any) => {
  return JSON.stringify(res, null, 2);
};

export async function getAllData() {

  try {
    const res = await mainAPI.get("/payment");

    const result = {
      status: res.status + "-" + res.statusText,
      headers: res.headers,
      data: res.data,
    };

    return fortmatResponse(result.data);
  } catch (err: any) {
    return fortmatResponse(err.response?.data || err);
  }
}

// export   async function getDataByGameId(gameId: number) {
//   console.log('[gameId]', gameId)
//   if (gameId) {
//     try {
//       const res = await apiClient.get(`/payment/game/${gameId}`);
//       console.log('[res]', res)

//       const result = {
//         data: res.data,
//         status: res.status,
//         statusText: res.statusText,
//         headers: res.headers,
//       };

//       return fortmatResponse(result.data);
//     } catch (err: any) {
//       console.log('[getDataByGameId err]', err)
//       return fortmatResponse(err.response?.data || err);
//     }
//   }
// }

export   async function getDataByAddress(address: string, chainId: number) {
  if (address) {
    try {
      const res = await mainAPI.get(`/payment/addy`, {
        params: {
          payment_address: address,
          chain_id: chainId
        }
      });

      const result = {
        data: res.data,
        status: res.status,
        statusText: res.statusText,
        headers: res.headers,
      };

      return fortmatResponse(result.data);
    } catch (err: any) {
      console.log('[getDataByAddress err]', err)
      return fortmatResponse(err.response?.data || err);
    }
  }
}

export async function getQRCScore(chainId: number) {
  try {
    const res = await mainAPI.get(`/get/qrc20_score/${chainId}`);

    const result = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
    };

    return fortmatResponse(result.data);
  } catch (err: any) {
    console.log('[getQRCScore err]', err)
    return fortmatResponse(err.response?.data || err);
  }
}

export async function getQTUMScore(chainId: number) {
  try {
    const res = await mainAPI.get(`/get/qtum_score/${chainId}`);

    const result = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
    };

    return fortmatResponse(result.data);
  } catch (err: any) {
    console.log('[getQTUMScore err]', err)
    return fortmatResponse(err.response?.data || err);
  }
}

export async function art_service_sign(signMessage: string) {
  try {
    const res = await artServiceAPI.post(`/auth`, {
      signature: signMessage
    });

    const result = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
    };

    return fortmatResponse(result);
  } catch (err: any) {
    console.log('[art service auth sign err]', err)
    return fortmatResponse(err.response?.data || err);
  }
}

export async function art_service_auth_check(signMessage: string) {
  try {
    const res = await artServiceAPI.get(`/auth/check`, {
      params: { signature: signMessage }
    });

    const result = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
    };

    return fortmatResponse(result);
  } catch (err: any) {
    console.log('[art service auth check err]', err)
    return fortmatResponse(err.response?.data || err);
  }
}
