import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
} from './Avatar.components'

interface AvatarProps {
  url: string
  size: number
  centerAvatar?: boolean
}

const Avatar: React.FC<AvatarProps> = ({
  url, size, centerAvatar = false
}) => {
  const { t } = useTranslation()

  return (
    <Container
      avatar={url}
      size={size}
      centerMargin={centerAvatar}
    >
    </Container>
  )
}

export default Avatar
