import { AbstractConnector } from '@qweb3-react-dev/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@qweb3-react-dev/core'
import { InjectedConnector, NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected } from '@qweb3-react-dev/injected-connector'
import { connectorLocalStorageKey } from 'Constants/Network'
import { useCallback } from 'react'
import { setupNetwork } from 'Utils/walletSetupMainNet'
import { notifyToast } from 'Utils/toast'

export const useWalletConnect = () => {
  const { activate, deactivate } = useWeb3React()
  const connect = useCallback(
    (connector: InjectedConnector | undefined, connectorId: string) => {
      if (connectorId) window.localStorage.setItem(connectorLocalStorageKey, connectorId)
      if (connector) {
        // connector.isAuthorized().then((isAuthorized: boolean) => {
        //   console.log('[isAthorized]', isAuthorized)
        //   if (isAuthorized) {
            activate(connector, async (error: Error) => {
              notifyToast({ id: error.name, type: 'error', content: error.message })
              if (error instanceof UnsupportedChainIdError) {
                const hasSetup = await setupNetwork()
                if (hasSetup) {
                  activate(connector)
                }
              } else {
                if (error instanceof NoEthereumProviderError) {
                  notifyToast({ id: 'Provider Error', type: 'error', content: 'No provider was found' })
                } else if (error instanceof UserRejectedRequestErrorInjected) {
                  notifyToast({ id: 'Authorization Error', type: 'error', content: 'Please authorize to access your account' })
                } else {
                  console.log('[connection error]', error)
                  notifyToast({ id: error.name, type: 'error', content: error.message })
                }
              }
            })
        //   }
        // })
      } else {
        notifyToast({ id: 'Unable to find connector', type: 'error', content: 'The connector config is wrong' })
      }
    },
    [activate]
  )
  const disconnect = useCallback(() => {
    deactivate()
  }, [deactivate])
  return { connect, disconnect }
}
