import React, { useEffect, ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/images/qtum_logo.svg'
import {
  Container,
  Header,
  HeaderContainer,
  BgWrapper,
  Logo,
  Title,
  Footer,
  FooterWidth,
  FooterLogo,
  FaqButton,
  InstructionContainer
} from './App.components'

interface HeaderProps {
  title?: string
  header?: ReactChild
  instruction?: ReactChild
}

const AppContainer: React.FC<HeaderProps> = ({
  title,
  header,
  instruction,
  children
}) => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()

  const handleFaq = () => {
    navigate('/faq')
  }

  return (
    <Container>
      <Header>
        <BgWrapper>
          <Logo src={logo}/>
          <Title>{title}</Title>
          <InstructionContainer>
            {instruction}
          </InstructionContainer>
          <HeaderContainer>
            {header}
          </HeaderContainer>
        </BgWrapper>
      </Header>
      {children}
      <Footer>
        <FooterWidth>
          <FooterLogo src={logo}/>
          <FaqButton onClick={handleFaq}>{t('faq')}</FaqButton>
        </FooterWidth>
      </Footer>
    </Container>
  )
}

export default AppContainer
