import { 
    QTUM_MAINNET_CHAIN_ID,
    QTUM_TESTNET_CHAIN_ID,
    QTUM_MAINNET_RPC_URL,
    QTUM_TESTNET_RPC_URL,
    QTUM_MAINNET_DOMAIN_URL,
    QTUM_TESTNET_DOMAIN_URL, 
    QTUM_MAINNET_NETWORK_ID,
    QTUM_TESTNET_NETWORK_ID,
    QTUM_MAINNET_DISPLAY_NAME
} from 'Constants/Network'

export const setupNetwork = async () => {
  const provider = window.qtum
  // const isProd = process.env.REACT_APP_ENV === 'prod'
  // const isProd = true
  if (provider) {
    const chainId = QTUM_MAINNET_CHAIN_ID
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `${chainId}` }], // chainId: 1 for Ethereum Mainnet
      })
      return true
    } catch (error: any) {
      console.log('[switch network error]', error)
      if (error.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `${chainId}`,
                chainName: QTUM_MAINNET_DISPLAY_NAME,
                nativeCurrency: {
                  name: 'QTUM',
                  symbol: 'QTUM',
                  decimals: 18,
                },
                rpcUrls: [
                  QTUM_MAINNET_RPC_URL
                ],
                blockExplorerUrls: [QTUM_MAINNET_DOMAIN_URL],
              },
            ],
          })
          return true
        } catch (error) {
          console.error('Failed to setup the network in Qnekt:', error)
          return false
        }
      }
    }
  } else {
    console.error("Can't setup the Qtum network on qnekt because window.qtum is undefined")
    return false
  }
}