import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

const tileFont = css`
  ${fontStyle}
  font-size: 21px;
  line-height: 30px;

  @media (max-width: 1355px) {
  }

  @media (max-width: 1215px) {
    font-size: 19px;
    line-height: 25px;
  }

  @media (max-width: 1075px) {
    font-size: 17px;
    line-height: 22px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
    line-height: 20px;
  }
`

export const Container = styled.div`
  display: inline-block;
  text-align: center;
  margin: .6%;
  position: relative;
  cursor: pointer;
  width: 240px
`

export const Name = styled.div`
  ${tileFont}
  margin-top: 20px;
  font-weight: 800;
`

export const Testnet = styled.div`
  color: #01FFFF;
`

export const Balance = styled.div`
  ${tileFont}
  color: #01FFFF;
`

export const SendButton = styled.div`
  ${fontStyle}
  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 12px;

  font-weight: bold;
  font-size: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2%;
  width: 80px;
  height: 35px;
  margin: .5% auto;

  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`
