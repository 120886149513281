import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  BgWrapper,
  Name,
  EnterButton
} from './GameTile.components'
import { Game } from 'Types/game'

const GameTile: React.FC<Game> = (game) => {
  const {
    id, gameNamespace, assetsFolderName, isActive
  } = game
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEnterGame = () => {
    // if (id === 'game001') {
      navigate(`game/${id}`)
    // }
  }

  return (
    <Container>
      <BgWrapper
        backgroundImage={require(`../../assets/images/${assetsFolderName}/tile.webp`)}
        isActive={isActive}
      />
      {isActive && <>
        <Name>{t('title', { ns: gameNamespace })}</Name>
        <EnterButton onClick={handleEnterGame}>{t('enterGame')}</EnterButton>
      </>}
    </Container>
  )
}

export default GameTile
