import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Name,
  TodayScore
} from './LeaderboardScore.components'
import { LeaderboardUser } from 'Types/user'
import { getBalanceNumber, getNumberFromBigNumber, shortenAddress } from 'Utils/web3Helper'
import { useScoring } from 'hooks'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const LeaderboardScore: React.FC<{gameId: string | undefined; winnerScore: number | undefined; winnerAddress: string[] | undefined}> = ({gameId, winnerScore, winnerAddress}) => {
  const { t } = useTranslation()

  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

  const userNameCopy = (userAddress: string) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(userAddress)
    } else {
      unsecuredCopyToClipboard(userAddress)
    }
    toast(`address ${shortenAddress(userAddress)} coppied!`)
  }

  return (
    <Container>
      <TodayScore>
        {gameId === 'game001' && (
          <>
            <Name>
              {t('qtumscore')}: 
            </Name>
            <Name>
              {winnerScore?.toFixed(2)},&nbsp;
            </Name>
            <Name>
              {t('qtumwinner')}: 
            </Name>
            {winnerAddress?.map((item, index) => {
              return (
                <Name key={index} onClick={() => userNameCopy(item!)}>{index === 0 ? `${shortenAddress(item!)}` : ` ,${shortenAddress(item!)}`}</Name>
            )})}
          </>
        )}
        {gameId === 'game002' && (
          <>
            <Name>
              {t('qrcscore')}: 
            </Name>
            <Name>
              {winnerScore?.toFixed(2)},&nbsp;
            </Name>
            <Name>
              {t('qrcwinner')}: 
            </Name>
            {winnerAddress?.map((item, index) => {
              return (
                <Name key={index} onClick={() => userNameCopy(item!)}>{index === 0 ? `${shortenAddress(item!)}` : `  ,${shortenAddress(item!)}`}</Name>
            )})}
          </>
        )}
      </TodayScore>
    </Container>
  )
}

export default LeaderboardScore
