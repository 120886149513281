import { createSelector } from 'reselect'

export const getState = (state: any) => state.app

export const isAppReady = createSelector(
  getState,
  ({ isAppReady }) => isAppReady
)

export const isSync = createSelector(
  getState,
  ({ isSync }) => isSync
)

export const signMessage = createSelector(
  getState,
  ({ signMessage }) => signMessage
)

export const artAccessToken = createSelector(
  getState,
  ({ artAccessToken }) => artAccessToken
)

export const artServiceAccess = createSelector(
  getState,
  ({ artServiceAccess }) => artServiceAccess
)