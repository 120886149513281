import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'Components'
import {
  Container,
  Order,
  UserInfo,
  Name,
  Address
} from './EmptyWalletTile.components'
import { LeaderboardOrderedUser, LeaderboardUser, User } from 'Types/user'
import { getNumberFromBigNumber } from 'Utils/web3Helper'
import { BigNumber } from 'ethers'

const EmptyWalletTile: React.FC<LeaderboardOrderedUser> = (user) => {
  const { t } = useTranslation()
  
  return (
    <Container>
      <Avatar
        url={require(`../../../assets/images/temp/${Math.floor(getNumberFromBigNumber(BigNumber.from(user.account), 47)) % 2 === 0 ? 'avatar01.webp' : 'avatar02.webp'}`)}
        size={100}
      />
      <Order>{user.pay_id}</Order>
      <UserInfo>
        <Name>{user.name}</Name>
        <Address>{user.account}</Address>
      </UserInfo>
    </Container>
  )
}

export default EmptyWalletTile
