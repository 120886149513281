import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

const tileFontStyle = css`
  ${fontStyle}
  font-weight: 800;
  font-size: 26px;
  line-height: 55px;
  margin-left: 4%;

  @media (max-width: 1355px) {
    font-size: 26px;
    line-height: 52px;
  }

  @media (max-width: 1215px) {
    font-size: 24px;
    line-height: 50px;
  }

  @media (max-width: 1075px) {
    font-size: 24px;
    line-height: 40px;
  }

  @media (max-width: 900px) {
    font-size: 22px;
    line-height: 38px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #CF4CC7;
  padding: 2% 0;
  cursor: pointer;
`

export const Order = styled.div`
  ${tileFontStyle}
  color: #01FFFF;
`

export const Name = styled.div`
  ${tileFontStyle}
`

export const Balance = styled.div`
  ${tileFontStyle}
  color: #01FFFF;
  font-size: 18px;
`

export const Score = styled.div`
  ${tileFontStyle}
`