
import styled, { css } from 'styled-components'
import { widthStyle, fontStyle } from 'Containers/App.components'

export const Container = styled.div`
  background: linear-gradient(180deg, #361792 12.18%, #13096A 58.38%);
  margin-top: -9%;
  padding-top: 2%;
  padding-bottom: 80px;
`

export const WidthWrapper = styled.div`
  ${widthStyle}
  position: relative;
`

export const GameSubHeaderBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  margin-top: 7%;
`

export const BottomContainer = styled.div`
  position: relative;
  min-height: 100px;
`

export const ReportButton = styled.div`
  ${fontStyle}
  position: absolute;
  right: 0;
  bottom: 0;
  margin-top: 5%;
  font-weight: bold;
  font-size: 23px;
  line-height: 33px;
  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  padding: .8% 2%;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 21px;
    line-height: 31px;
  }

  @media (max-width: 1215px) {
    font-size: 19px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 17px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
  }
`

const modalContentFont = css`
  ${fontStyle}
  color: black;

  font-weight: bold;
  font-size: 21px;
  line-height: 29px;

  margin-top: 5%;

  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 22px;
  }
`

export const ModalContent = styled.div`
  padding: 0 3%;
`

export const ModalTitle = styled.h2`
  ${fontStyle}
  color: black;
  text-transform: uppercase;

  font-size: 32px;
  line-height: 44px;

  @media (max-width: 1355px) {
    font-size: 30px;
  }

  @media (max-width: 1215px) {
    font-size: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 26px;
  }

  @media (max-width: 900px) {
    font-size: 24px;
  }
`

export const ModalDescription = styled.div`
  ${modalContentFont}
`

export const ModalInput = styled.textarea`
  ${modalContentFont}
  background: #F6F6F6;
  border: 1px solid #000000;
  box-sizing: border #553030;

  width: 92%;
  height: 300px;
  resize: none;
  padding: 3%;
`
  
export const ModalEmailInput = styled.input`
  ${modalContentFont}
  background: #F6F6F6;
  border: 1px solid #000000;
  box-sizing: border #553030;

  width: 92%;
  // height: 50px;
  resize: none;
  padding: 3%;
`

export const ModalButton = styled.button`
  ${fontStyle}

  font-weight: bold;
  font-size: 24px;
  line-height: 33px;

  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 20px;

  padding: 1% 8%;
  float: right;
  margin-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 16px;
  }
`