import { InjectedConnector } from "@qweb3-react-dev/injected-connector";
import { AbstractConnector } from "@qweb3-react-dev/abstract-connector"
export const QTUM_MAINNET = 'qtumMainnet';
export const QTUM_TESTNET = 'qtumTestnet';
export const QTUM_REGTEST = 'qtumRegtest';

export const QTUM_MAINNET_NETWORK_ID = '81';
export const QTUM_TESTNET_NETWORK_ID = '8889';
export const QTUM_REGTEST_NETWORK_ID = '8890';

export const QTUM_MAINNET_CHAIN_ID = '0x51'
export const QTUM_TESTNET_CHAIN_ID = '0x22b9'
export const QTUM_REGTEST_CHAIN_ID = '0x22ba'

export const QTUM_MAINNET_DISPLAY_NAME = 'QTUM Mainnet';
export const QTUM_TESTNET_DISPLAY_NAME = 'QTUM Testnet';
export const QTUM_REGTEST_DISPLAY_NAME = 'QTUM Regtest';

export const QTUM_MAINNET_RPC_URL = `https://janus.qiswap.com/api/`;
export const QTUM_TESTNET_RPC_URL = `https://testnet-janus.qiswap.com/api/`;
export const QTUM_REGTEST_RPC_URL = `https://localhost:23889`;

export const QTUM_MAINNET_DOMAIN_URL = `https://qtum.info/api/`;
export const QTUM_TESTNET_DOMAIN_URL = `https://testnet.qtum.info/api/`;
export const QTUM_REGTEST_DOMAIN_URL = `https://localhost:23889`;

export const Qnekt_ERC20 = '0x04bb530ce7fc4e2ccbeddc018e415e3071cb356b'

export const DEFAULT_GAS_LIMIT = '22000'
export const DEFAULT_GAS_PRICE = '40'

interface WalletInfo {
    connector?: InjectedConnector | AbstractConnector
    connectorId?: string
}

export const SERVICE_ID = process.env.REACT_APP_SERVICE_ID
export const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY

const injected = new InjectedConnector({ supportedChainIds: [Number(QTUM_MAINNET_NETWORK_ID), Number(QTUM_TESTNET_NETWORK_ID), Number(QTUM_REGTEST_NETWORK_ID)]})

export const QNEKT_CONFIG : WalletInfo = {
    connector: injected,
    connectorId: "injected",
}

export const connectorLocalStorageKey = 'connectorId'