import AppContainer from 'Containers/App'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { GameTile } from 'Components'
import games from 'Constants/games.json'
import { Instruction } from 'Components/Instruction'

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Home = () => {
  const { t } = useTranslation()

  return (
    <AppContainer
      title={t('title')}
      header={<Container>
        {games.map((game, index) => <GameTile {...{...game}} key={index} />)}
      </Container>}
      instruction={<Instruction />}
    />
  )
}

export default Home