import styled from "styled-components";
import { fontStyle } from 'Containers/App.components'

export const Container = styled.div`
  ${fontStyle}
  font-weight: 800;
  font-size: 32px;
  line-height: 45px;
  position: relative;
  margin-top: 7%;
  padding: 0 2%;
`

export const UsersList = styled.div`
  margin-top: 4%;
`
