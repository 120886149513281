import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Avatar } from 'Components'
import {
  Container,
  Order,
  Name,
  Balance,
  Score
} from './LeaderboardTile.components'
import { LeaderboardOrderedUser, LeaderboardUser } from 'Types/user'
import { getBalanceNumber, getBigNumberFromString, getNumberFromBigNumber } from 'Utils/web3Helper'
import { BigNumber } from 'ethers'
import { useUserInfo } from 'hooks'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isSync } from 'Store/Selectors/App'

const LeaderboardTile: React.FC<LeaderboardOrderedUser> = (user) => {
  const { t } = useTranslation()
  let { gameId } = useParams()
  const { qtumBalance, qrcBalance, handleQtumBalance, handleQRC20Balance } = useUserInfo()
  const isSyncCheck = useSelector(isSync)

  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }
  const userNameCopy = (userAddress: string) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(userAddress)
    } else {
      unsecuredCopyToClipboard(userAddress)
    }
    toast(`${user.name}'s address coppied!`)
  }

  useEffect(() => {
    if (isSyncCheck) {
      handleQtumBalance(user.account)
      handleQRC20Balance(user.account)
    }
  }, [isSyncCheck])

  useEffect(() => {
    handleQtumBalance(user.account)
    handleQRC20Balance(user.account)
  }, [handleQtumBalance, handleQRC20Balance])

  return (
    <Container>
      <Avatar
        url={require(`../../../assets/images/temp/${Math.floor(getNumberFromBigNumber(BigNumber.from(user.account), 47)) % 2 === 0 ? 'avatar01.webp' : 'avatar02.webp'}`)}
        size={100}
      />
      <Order>{user.pay_id}</Order>
      <Name onClick={() => userNameCopy(user.account)}>{user.name}</Name>
      {gameId === 'game001' ? (
        <Balance>({t('balance', { unit: user.unit })}: {qtumBalance !== undefined ? getBalanceNumber(qtumBalance!) : '0.00'})</Balance>
      ) : (
        <Balance>({t('balance', { unit: user.unit })}: {qrcBalance !== undefined ? getBalanceNumber(qrcBalance!) : '0.00'})</Balance>
      )}
      <Score>{Number(user.score)?.toFixed(2)}</Score>
    </Container>
  )
}

export default LeaderboardTile
