import AppContainer from 'Containers/App'
import styled, { css } from "styled-components";
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FaqItem } from 'Components'
import { fontStyle } from 'Containers/App.components'
import faqs from 'Constants/faq.json'

const headerFontStyle = css`
  ${fontStyle}
  font-size: 21px;
  line-height: 29px;
  font-weight: bold;

  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 28px;
    margin-right: 7%;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 22px;
  }
`

export const BackButton = styled.div`
  ${headerFontStyle}
  cursor: pointer;
  margin-bottom: 1%;
  float: left;
`

const Faq = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <AppContainer
      title={t('faq')}
      header={<>
        <BackButton onClick={handleBack}>{t('back')}</BackButton>
        {faqs.map(faq => <FaqItem {...{...faq}} />)}
      </>}
    />
  )
}

export default Faq