import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

interface BgProps {
  backgroundImage: string
  isActive?: boolean
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  
  border: 4px solid #FFFFFF;
  border-radius: 12px;
  margin: .8%;
  
  background-color: #0645F7;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 0;
  padding-top: 57%;
  position: relative;
`

export const BgWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(${({ backgroundImage }: BgProps) => backgroundImage}) no-repeat center;
  background-size: cover;
  ${({ isActive }: BgProps) => !isActive && css`filter: blur(5px);` }
`

export const Name = styled.div`
  ${fontStyle}
  background: rgba(64, 132, 174, 0.8);
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 12px;

  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  text-align: center;

  width: 92%;
  min-height: 24%;
  padding: 0 18%;
  position: absolute; 
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 11%;

    @media (max-width: 1355px) {
      font-size: 24px;
      line-height: 34px;
    }

    @media (max-width: 1215px) {
      font-size: 22px;
      line-height: 32px;
    }

    @media (max-width: 1075px) {
      font-size: 20px;
      line-height: 30px;
    }

    @media (max-width: 900px) {
      font-size: 16px;
      line-height: 25px;
    }
`

export const EnterButton = styled.div`
  ${fontStyle}
  background: #3F1B99;
  border: 2px solid #D554CB;
  box-sizing: border-box;
  border-radius: 12px;

  font-weight: bold;
  font-size: 25px;

  width: 75%;
  bottom: 4%;
  min-height: 10%;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`