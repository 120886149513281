import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

export const Container = styled.div`
  ${fontStyle}
  font-weight: 800;
  font-size: 32px;
  line-height: 45px;
  position: relative;
  margin-top: 7%;
  padding: 0 2%;
`

export const UsersGrid = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-color: rebeccapurple green;
  margin-top: 3%;
`
const modalContentFont = css`
  ${fontStyle}
  color: black;

  font-weight: bold;
  font-size: 21px;
  line-height: 29px;

  margin-top: 5%;

  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 22px;
  }
`

export const ModalContent = styled.div`
  padding: 0 3%;
`

export const ModalTitle = styled.h2`
  ${fontStyle}
  color: black;
  text-transform: uppercase;

  font-size: 32px;
  line-height: 44px;

  @media (max-width: 1355px) {
    font-size: 30px;
  }

  @media (max-width: 1215px) {
    font-size: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 26px;
  }

  @media (max-width: 900px) {
    font-size: 24px;
  }
`

export const ModalDescription = styled.div`
  ${modalContentFont}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 0 !important;
`

export const ModalTargetInfo = styled.div`
  ${modalContentFont}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0 !important;
`

export const ModalSignerInfo = styled.div`
  ${modalContentFont}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0 !important;
`

export const ModalSpan = styled.span`
  ${modalContentFont}
`
  
export const ModalInput = styled.input`
  ${modalContentFont}
  background: #F6F6F6;
  border: 1px solid #000000;
  box-sizing: border #553030;
  margin-top: 0 !important;

  width: 100%;
  // height: 50px;
  resize: none;
  padding: 3%;
  position: relative;
`

export const ModalSendOption = styled.span`
  ${modalContentFont}

  cursor: pointer;
  text-align: center;
  width: 50px;
  margin: auto auto;
  &:hover {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }
`

export const ModalSendOptionBox = styled.div`
  ${modalContentFont}

  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  margin-right: 10px;
`

export const ModalAmountBox = styled.div`
  ${modalContentFont}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`

export const ModalButton = styled.button`
  ${fontStyle}

  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  width: 120px;
  height: 46px;
  text-align: center;

  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 20px;

  // padding: 1% 5%;
  float: right;
  margin-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 16px;
  }
`
