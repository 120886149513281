import React, { useCallback, useState } from "react";
import { useActiveWeb3React } from "hooks";
import { LeaderboardOrderedUser } from "Types/user";
import { getQRCScore, getQTUMScore } from "Utils/apiHelper";

export const useScoring = () => {
  const { chainId } = useActiveWeb3React()
  const [qrcScore, setQrcScore] = useState<number | undefined>()
  const [qrcWinner, setQrcWinner] = useState<string[] | undefined>()
  const [qrcOrderList, setQrcOrderList] = useState<LeaderboardOrderedUser[] | undefined>()

  const [qtumScore, setQtumScore] = useState<number | undefined>()
  const [qtumWinner, setQtumWinner] = useState<string[] | undefined>()
  const [qtumOrderList, setQtumOrderList] = useState<LeaderboardOrderedUser[] | undefined>()

  const handleQTUMScore = useCallback(async () => {
    if (!chainId) return;
    const scoreDataStr = await getQTUMScore(chainId)
    const scoreData = JSON.parse(scoreDataStr)
    setQtumScore(Number(scoreData.winner_info.point!))
    setQtumWinner([...scoreData.winner_info.account])
    setQtumOrderList([...scoreData.order_list])
  }, [chainId])

  const handleQRCScore = useCallback(async () => {
    if (!chainId) return;
    const scoreDataStr = await getQRCScore(chainId)
    const scoreData = JSON.parse(scoreDataStr)
    setQrcScore(Number(scoreData.winner_info.point!))
    setQrcWinner([...scoreData.winner_info.account])
    setQrcOrderList([...scoreData.order_list])
  }, [chainId])

  return { qrcScore, qrcWinner, qrcOrderList, qtumScore, qtumWinner, qtumOrderList, handleQRCScore, handleQTUMScore }
}