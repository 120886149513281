export const APP_READY = 'app/READY_TO_USE'

export const AppReady = () => ({
  type: APP_READY
})

export const SYNC_BALANCE = 'app/SYNC_BALANCE'

export const SyncBalance = (value: boolean) => ({
  type: SYNC_BALANCE,
  value
})

export const SIGN_REQUEST = 'app/SIGN_REQUEST'

export const SignRequest = (value: string) => ({
  type: SIGN_REQUEST,
  value
})

export const SIGN_MESSAGE = 'app/SIGN_MESSAGE'

export const SignMessage = (value: string) => ({
  type: SIGN_MESSAGE,
  value
})

export const ART_ACCESS_TOKEN = 'app/ART_ACCESS_TOKEN'

export const ArtAceessToken = (value: string) => ({
  type: ART_ACCESS_TOKEN,
  value
})

export const ART_SERVICE_CHECK = 'app/ART_SERVICE_CHECK'

export const ArtServiceCheck = (value: string) => ({
  type: ART_SERVICE_CHECK,
  value
})

export const ART_SERVICE_ACCESS = 'app/ART_SERVICE_ACCESS'

export const ArtServiceAccess = (value: boolean) => ({
  type: ART_SERVICE_ACCESS,
  value
})
