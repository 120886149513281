import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal';
import ColorPaletteTile from './ColorPaletteTile/ColorPaletteTile'
import {
  Container,
  ColorsGrid,
  CanvasContainer,
  ControlContainer,
  ControlWrapper,
  GridView,
  ZoomControl,
  PaintButton,
  ModalContent,
  ModalDescription,
  ModalTitle
} from './ColorPalette.components'
import { Color } from 'Types/color'
import { renderCanvas, styleChildren } from './CanvasHelper'
import { ModalButton } from 'Routes/Game.components';
import { useUserInfo } from 'hooks';
import { useSelector } from 'react-redux';
import { artServiceAccess, signMessage } from 'Store/Selectors/App';

const modalStyles = {
  overlay: {
    backgroundColor: '#150755b0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '50%',
    width: '50%'
  },
};

const ColorPalette: React.FC<{colors: Color[]}> = ({colors}) => {
  const { t } = useTranslation()
  const  { handleGetSignature, handleCheckArtService } = useUserInfo()
  const [ signModalOpen, setSignModalOpen ] = useState<boolean>(true)
  const artAccess = useSelector(artServiceAccess)
  const signature = useSelector(signMessage)

  let authCheck: NodeJS.Timer | undefined

  const openModal = useCallback((isOpen: boolean) => {
    setSignModalOpen(isOpen)
  }, [])

  const state = {
    resolution: {w: 100, h: 100},
    pixel: 8,
    mouseDown: false,
    color: colors[0].code,
    bgColor: 'rgb(234, 225, 208)'
  };

  const colorPickUp = useCallback((color: string) => {
    state.color = color
  }, [])

  const initCanvas = () => {
    const grid = document.getElementById('grid')
    const zoom = document.getElementById('pixel')
    const fillButton = document.getElementById('fill')
    const eraseButton = document.getElementById('erase')

    renderCanvas(grid, state.resolution, state.pixel, function(ev: any) {
      if (state.mouseDown || ev.type === 'click')
        ev.target.style.backgroundColor = state.color
    })

    grid && grid.addEventListener('mousedown', function(ev) {
      state.mouseDown = true
    })
    document.addEventListener('mouseup', function(ev) {
      state.mouseDown = false
    })

    zoom && zoom.addEventListener('input', function(ev: any) {
      state.pixel = ev.target.value
      if (grid) {
        grid.style.width = `${state.resolution.w * state.pixel}px`
        grid.style.height = `${state.resolution.h * state.pixel}px`
      }
      styleChildren(grid, {width: `${state.pixel}px`, height: `${state.pixel}px`})
    })

    fillButton && fillButton.addEventListener('click', function(ev: any) {
      styleChildren(grid, {backgroundColor: state.color})
    })

    eraseButton && eraseButton.addEventListener('click', function(ev: any) {
      styleChildren(grid, {backgroundColor: state.bgColor})
    })
  }

  const artServiceCheck = useCallback(async () => {
    if (signature !== '') {
      await handleCheckArtService(signature)
    }
  }, [signature])

  useEffect(() => {
    if (signature && authCheck) clearInterval(authCheck)
    authCheck = setInterval(artServiceCheck, 10000)

    return () => {
      clearInterval(authCheck)
    }
  }, [signature])

  useEffect(() => {
    initCanvas()
  }, [])

  const artAccessCheck = useCallback(() => {
    if (!artAccess) {
      // notifyToast({ id: 'sign message', type: 'error', content: `Art service access failed. \n You have to sign your message again.` })
      openModal(true)
    } else {
      openModal(false)
    }
  }, [artAccess])

  useEffect(() => {
    artAccessCheck()
  }, [artAccess, artAccessCheck])
  
  return (
    <>
      <Container>
        {t('colorPalette')}
        <ColorsGrid>
          {colors.map((color, index) =>
            <ColorPaletteTile
              color={color}
              onClick={(color) => colorPickUp(color)}
              key={index}
            />
          )}
        </ColorsGrid>
        <ControlContainer>
          <ControlWrapper>
            {t('zoom')}
            <ZoomControl id="pixel" type="range" name="pixelSide" defaultValue={'8'} step="2" min="6" max="50"/>
          </ControlWrapper>
          <ControlWrapper>
            <PaintButton id="fill">{t('fill')}</PaintButton>
            <PaintButton id="erase">{t('erase')}</PaintButton>
          </ControlWrapper>
        </ControlContainer>
        <CanvasContainer>
          <GridView id="grid"></GridView>
        </CanvasContainer>
      </Container>
      <Modal
        isOpen={signModalOpen}
        // onRequestClose={() => openModal(false)}
        style={modalStyles}
        >
        <ModalContent>
          <ModalTitle>{t('signMessage')}</ModalTitle>
          <ModalDescription>{t('signMessageContent')}</ModalDescription>
          <ModalButton type={'button'} onClick={() => handleGetSignature()}>{t('sign')}</ModalButton>
        </ModalContent>
      </Modal>
    </>  
  )
}

export default ColorPalette
