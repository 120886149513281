import axios from "axios";

export const mainAPI = axios.create({
  // baseURL: "https://nft.usocoins.com.br:5000/api",
  baseURL: "https://qnektpay.qtum.info/api/", //44.206.51.94
  // baseURL: "http://127.0.0.1:5000/api/",
  headers: {
    "Content-type": "application/json",
    "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY0OTE2NDA0NCwianRpIjoiYzBlOGE4ZGItMTM0ZC00ZDkxLWJkZjAtM2YyYzFjMjExMDQ0IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6InF0dW0iLCJuYmYiOjE2NDkxNjQwNDQsImV4cCI6MTY4MDcwMDA0NH0.2HBSZ37urK6FR9WL-kTwdKA_GM5U2IBuoTA87l1KbrE",
  }
});

export const artServiceAPI = axios.create({
  // baseURL: "https://nft.usocoins.com.br:5000/api",
  baseURL: "https://qnekt.qtum.info/api/", //44.206.51.94
  // baseURL: "http://127.0.0.1:9000/api/",
  headers: {
    "Content-type": "application/json",
  }
});
