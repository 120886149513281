import styled from "styled-components";
import { fontStyle } from 'Containers/App.components'

export const Container = styled.div`
  width: 100%;
  display: inline-block;
  // margin-top: 7%;
`

export const ConnectButton = styled.div`
  ${fontStyle}
  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 12px;

  font-weight: bold;
  font-size: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: .8% 2%;
  float: right;

  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`

export const DiscordConnectButton = styled.a`
  ${fontStyle}
  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 12px;

  margin-left: 5px;

  font-weight: bold;
  font-size: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: .8% 2%;
  float: right;

  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`

export const ConnectedAccount  = styled.div`
  ${fontStyle}
  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 12px;

  font-weight: bold;
  font-size: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: .8% 2%;
  float: right;

  cursor: pointer;

  @media (max-width: 1355px) {
    font-size: 22px;
  }

  @media (max-width: 1215px) {
    font-size: 20px;
  }

  @media (max-width: 1075px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
  }
`