import styled from "styled-components";

interface BgProps {
  colorCode: string
  size: number
}

export const Container = styled.div`
  display: inline-block;
  margin: .8%;
  position: relative;
  cursor: pointer;
  background-color: ${({ colorCode }: BgProps) => colorCode};
  width: ${({ size }: BgProps) => `${size}px`};
  height: ${({ size }: BgProps) => `${size}px`};
  border: 3px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 20px;
`