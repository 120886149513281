import { FormEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal';
import AppContainer from 'Containers/App'
import {
  GameHeader,
  ConnectWalletButton,
  Participants,
  Leaderboard,
  EmptyWallets,
  ColorPalette
} from 'Components'
import {
  Container,
  WidthWrapper,
  BottomContainer,
  ReportButton,
  ModalContent,
  ModalTitle,
  ModalDescription,
  ModalInput,
  ModalButton,
  GameSubHeaderBox,
  ModalEmailInput
} from './Game.components'
import { Game as GameType } from 'Types/game'
import emailjs from '@emailjs/browser'

// TODO: - test
import games from 'Constants/games.json'
import { getAllData } from 'Utils/apiHelper';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useUserInfo, useEagerConnect, useScoring } from 'hooks';
import GameSubHeader from 'Components/GameSubHeader/GameSubHeader';
import { LeaderboardOrderedUser } from 'Types/user';
import { PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID } from 'Constants/Network';
import { notifyToast } from 'Utils/toast';
import { Game1Instruction } from 'Components/Instruction';

const modalStyles = {
  overlay: {
    backgroundColor: '#150755b0'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '50%',
    width: '50%'
  },
};

const Game = () => {
  const { t } = useTranslation()
  const reportForm = useRef<HTMLFormElement>(null)

  const { account, connector, error } = useActiveWeb3React()

  const  { signerData, handleSignerData } = useUserInfo()

  let { gameId } = useParams()
  const [game, setGame] = useState<GameType>()
  const [userData, setUserData] = useState<any>()
  const [userList, setUserList] = useState<LeaderboardOrderedUser[]>([])
  const { qrcScore, qrcWinner, qrcOrderList, handleQRCScore, qtumScore, qtumWinner, qtumOrderList, handleQTUMScore } = useScoring()
  const [score, setScore] = useState<any>()
  const [winner, setWinner] = useState<string[]>()

  const [reportModalOpen, setReportModalOpen] = useState(false)
  const openModal = (isOpen: boolean) => () => {
    if (!account) {
      notifyToast({ id: 'deactive account', type: 'error', content: 'Please connect to qnekt.' })
    } else {
      setReportModalOpen(isOpen)
    }
  }

  const EagerConnect = () => {
    useEagerConnect()
    return null
  }

  const sendReport = (e: FormEvent) => {
    e.preventDefault()
    emailjs.sendForm(SERVICE_ID!, TEMPLATE_ID!, reportForm.current!, PUBLIC_KEY!)
    .then((result) => {
      setReportModalOpen(false)
    }, (error) => {
      console.log('[send report error]', error.text)
    })
  }

  useEffect(() => {
    reportModalOpen
      ? document.body.style.overflow = 'hidden'
      : document.body.style.overflow = 'unset'
  }, [ reportModalOpen ]);

  useEffect(() => {
    if (account && signerData === undefined) {
      handleSignerData(account)
    }
  }, [account, gameId])

  useEffect(() => {
    if (gameId === 'game002') {
      handleQRCScore()
    } else if (gameId === 'game001') {
      handleQTUMScore()
    }
  }, [gameId, handleQRCScore, handleQTUMScore])

  useEffect(() => {
    if (gameId === 'game001' && qtumOrderList !== undefined) {
      setUserList([...qtumOrderList])
      setScore(qtumScore)
      setWinner([...qtumWinner!])
    } else if (gameId === 'game002' && qrcOrderList !== undefined) {
      setUserList([...qrcOrderList])
      setScore(qrcScore)
      setWinner([...qrcWinner!])
    }
  }, [gameId, qtumOrderList, qrcOrderList])

  useEffect(() => {
    const getGame = async () => {
      let gameSelected = games.find((game: GameType) => game.id === gameId)
      const userList = await getAllData()
      setUserData([...JSON.parse(userList!)])
      setGame(gameSelected)
    }
    getGame()
  }, [])

  return (
    <AppContainer
      title={t('title', { ns: game && game.gameNamespace })}
      header={game && <GameHeader {...{...game}} />}
    >
      {/* {!account && <EagerConnect />}       */}
      <Container>
        <WidthWrapper>
          <GameSubHeaderBox>
            {game && <GameSubHeader signerData={signerData} gameId={gameId!} />}
            {game && <ConnectWalletButton signerData={signerData} />}
          </GameSubHeaderBox>
          {/* {game && game.participants && <Participants users={game.participants}/>} */}
          {gameId !== 'game003' && userData && <Participants users={userData}/>}
          {gameId !== 'game003' && userList && <Leaderboard users={userList} score={score!} winner={winner!} />}
          {gameId !== 'game003' && userList && <EmptyWallets users={userList} gameId={gameId!}/>}
          {game && game.id === 'game003' && game.colorPalette && <ColorPalette colors={game.colorPalette}/>}
          <BottomContainer>
            <ReportButton onClick={openModal(true)}>{t('reportBug')}</ReportButton>
          </BottomContainer>
        </WidthWrapper>
      </Container>
      <Modal
        isOpen={reportModalOpen}
        onRequestClose={openModal(false)}
        style={modalStyles}
      >
        <ModalContent>
          <ModalTitle>{t('reportBug')}</ModalTitle>
          {/* <ModalDescription>{t('reportBugDescription')}</ModalDescription> */}
          <form ref={reportForm} onSubmit={sendReport}>
            {/* <ModalEmailInput id={'user_email'} name={'user_email'} placeholder={t('email')} /> */}
            <ModalInput id={'message'} name={'message'} placeholder={t('description')} />
            <ModalButton type={'submit'}>{t('send')}</ModalButton>
            <input type={'hidden'} name={'user_name'} defaultValue={signerData?.discord_name!} />
          </form>
        </ModalContent>
      </Modal>
    </AppContainer>
  )
}

export default Game