import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

export const Container = styled.div`
  ${fontStyle}
  font-weight: 800;
  font-size: 32px;
  line-height: 45px;
  position: relative;
  margin-top: 7%;
  padding: 0 2%;
`

export const ColorsGrid = styled.div`
  margin-top: 3%;
  width: 55%;
`

export const ControlContainer = styled.div`
  user-select: none;
  width: 100%;
  display: flex;
  flex-wrap: row;
  justify-content: space-between;
  margin-top: 2%;
`

export const ControlWrapper = styled.div``

export const ZoomControl = styled.input``

export const GridView = styled.div`
  display: inline-block;
  background-color: rgb(234, 225, 208);

  &:active {
    cursor: pointer;
  }
  &>div {
    float: left;
    border: 1px solid grey;
    box-sizing: border-box;
  }
`

export const PaintButton = styled.button`
  ${fontStyle}
  display: inline-block;
  font-weight: bold;
  font-size: 23px;
  line-height: 33px;
  background: #55AEFF;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 10px;

  @media (max-width: 1355px) {
    font-size: 21px;
    line-height: 31px;
  }

  @media (max-width: 1215px) {
    font-size: 19px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 17px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
  }
`

export const CanvasContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: 100vh;
  margin-top: 2%;
`

export const ClearButton = styled.button`
`

const modalContentFont = css`
  ${fontStyle}
  color: black;

  font-weight: bold;
  font-size: 21px;
  line-height: 29px;

  margin-top: 5%;

  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 22px;
  }
`

export const ModalContent = styled.div`
  padding: 0 3%;
`

export const ModalTitle = styled.h2`
  ${fontStyle}
  color: black;
  text-transform: uppercase;

  font-size: 32px;
  line-height: 44px;

  @media (max-width: 1355px) {
    font-size: 30px;
  }

  @media (max-width: 1215px) {
    font-size: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 26px;
  }

  @media (max-width: 900px) {
    font-size: 24px;
  }
`

export const ModalDescription = styled.div`
  ${modalContentFont}
`
