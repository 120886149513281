import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Question,
  Answer
} from './FaqItem.components'
import { Faq } from 'Types/faq'

const FaqItem: React.FC<Faq> = ({ question, answer }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Question>{question}</Question>
      <Answer>{answer}</Answer>
    </Container>
  )
}

export default FaqItem
