import styled from "styled-components";
import { fontStyle } from 'Containers/App.components'

interface BgProps {
  avatar: string
  size: number
  centerMargin?: boolean
}

export const Container = styled.div`
  background: linear-gradient(137.82deg, #4C25E6 6.7%, #5227E4 17.35%, #642CE0 31.03%, #8235D9 47.76%, #AB41CF 65.26%, #D94FC4 82.75%);
  background: url(${({ avatar }: BgProps) => avatar}) no-repeat center;
  background-size: cover;
  width: ${({ size }: BgProps) => `${size}px`};
  min-width: ${({ size }: BgProps) => `${size}px`};
  height: ${({ size }: BgProps) => `${size}px`};
  min-height: ${({ size }: BgProps) => `${size}px`};
  position: relative;
  border-radius: 10%;

  &:before {
    position: absolute;
    content: '';
    display: block;
    border-radius: 15%;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: ${({ size }: BgProps) => `${size > 100 ? '3' : '2'}px`} solid transparent;
    background: white;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
  }

  margin: ${({ centerMargin }: BgProps) => centerMargin ? '0 auto' : 'initial' }
`
