import { put, call, take, fork, all } from 'redux-saga/effects'
import { AppReady, ArtAceessToken, ArtServiceAccess, ArtServiceCheck, ART_SERVICE_CHECK, SignMessage, SignRequest, SIGN_REQUEST } from 'Store/Actions/App'
import { art_service_auth_check, art_service_sign } from 'Utils/apiHelper'

export function* artService() {
  yield fork(artServiceAuth)
  yield fork(artServiceCheck)
}

function * artServiceAuth() {
  while(true) {
    try {
      const action: ReturnType<typeof SignRequest> = yield take(SIGN_REQUEST)
      console.log('[art service auth dispatch]', action)
      const { payload, error } = yield call(authSign, action.value)
      if (!error) {
        yield all([ 
          put(SignMessage(payload.signature)),
          put(ArtAceessToken(payload.art_access_token)),
          put(ArtServiceAccess(true))
        ])
      }
    } catch (e) {
      console.log('[art service auth error]', e)
    }
  }
}

async function authSign(signature: string) {
  try {
    const res = await art_service_sign(signature)
    const result = JSON.parse(res)
    if (result.status === 200) {
      return {payload: { signature, art_access_token: result.data.access_token }, error: null}
    } else {
      return {payload: null, error: 'forbidden'}
    }
  } catch (e) {
    console.log('[auth sign api error]', e)
    return {payload: null, error: e}
  }
}

function * artServiceCheck() {
  while (true) {
    try {
      const action: ReturnType<typeof ArtServiceCheck> = yield take(ART_SERVICE_CHECK)
      console.log('[art service auth arg check]', action)
      const { payload, error } = yield call(artAuthCheck, action.value)
      if (!error) {
        yield all([ 
          put(SignMessage(payload.signature)),
          put(ArtAceessToken(payload.art_access_token)),
          put(ArtServiceAccess(true))
        ])
      } else {
        yield all([
          put(SignMessage('')),
          put(ArtAceessToken('')),
          put(ArtServiceAccess(false))
        ])
      }
    } catch (e) {
      console.log('[art service auth check error]', e)
      yield all([
        put(SignMessage('')),
        put(ArtAceessToken('')),
        put(ArtServiceAccess(false))
      ])
    }
  }
}

async function artAuthCheck(signature: string) {
  try {
    const res = await art_service_auth_check(signature)
    const result = JSON.parse(res)
    if (result.status === 200) {
      return {payload: { signature, art_access_token: result.data.access_token }, error: null}
    } else {
      return {payload: null, error: 'forbidden'}
    }
  } catch (e) {
    console.log('[auth sign api error]', e)
    return {payload: null, error: e}
  }
}

export default artService