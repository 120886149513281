import { useEffect } from "react";
import { connectorLocalStorageKey } from "Constants/Network";
import { useWalletConnect } from "./useWalletConnect";
import { QNEKT_CONFIG } from "Constants/Network";
import { InjectedConnector } from "@qweb3-react-dev/injected-connector";

export const useEagerConnect = () => {
  const { connect } = useWalletConnect()

  useEffect(() => {
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey)
    if (connectorId) {
      connect(QNEKT_CONFIG.connector as InjectedConnector, connectorId)
    }
  }, [connect])
}