import styled, { css } from "styled-components";
import { fontStyle } from 'Containers/App.components'

interface BgProps {
  backgroundImage: string
  isActive?: boolean
}

const headerFontStyle = css`
  ${fontStyle}
  font-size: 21px;
  line-height: 29px;
  font-weight: bold;

  @media (max-width: 1355px) {
    font-size: 20px;
    line-height: 28px;
    margin-right: 7%;
  }

  @media (max-width: 1215px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (max-width: 1075px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 22px;
  }
`

const contentFontStyle = css`
  ${fontStyle}
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;

  @media (max-width: 1355px) {
    font-size: 18px;
    line-height: 26px;
    margin-right: 7%;
  }

  @media (max-width: 1215px) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 1075px) {
    font-size: 14px;
    line-height: 22px;
  }

  @media (max-width: 900px) {
    font-size: 12px;
    line-height: 20px;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 0;
  padding-top: 25%;
  padding-bottom: 25%;
  border: 3px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 20px;
  background: url(${({ backgroundImage }: BgProps) => backgroundImage}) no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`

export const BackButton = styled.div`
  ${headerFontStyle}
  cursor: pointer;
  margin-bottom: 1%;
`

export const TitleWrapper = styled.div`
  ${headerFontStyle}
  text-align: left;
  width: 49%;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
`

export const Description = styled.div`
  ${contentFontStyle}
  text-align: left;
  width: 49%;
  margin-right: 2%;
  word-break: break-all;
`