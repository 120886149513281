import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LeaderboardTile from './LeaderboardTile/LeaderboardTile'
import {
  Container,
  UsersList
} from './Leaderboard.components'
import { LeaderboardOrderedUser, LeaderboardUser, User } from 'Types/user'
import LeaderboardScore from './LeaderboardScore/LeaderboardScore'
import { useParams } from 'react-router-dom'
import { useScoring } from 'hooks'
import { ThreeDots } from 'react-loader-spinner'

const Leaderboard: React.FC<{users: LeaderboardOrderedUser[]; score: number; winner: string[]}> = ({users, score, winner}) => {
  const { t } = useTranslation()
  let { gameId } = useParams()
  // const { qrcScore, qrcWinner, qrcOrderList, handleQRCScore, qtumScore, qtumWinner, qtumOrderList, handleQTUMScore } = useScoring()
  // const [userList, setUserList] = useState<LeaderboardOrderedUser[]>([])
  // useEffect(() => {
  //   if (gameId === 'game002') {
  //     handleQRCScore()
  //   } else if (gameId === 'game001') {
  //     handleQTUMScore()
  //   }
  // }, [gameId, handleQRCScore, handleQTUMScore])

  // useEffect(() => {
  //   if (gameId === 'game001' && qtumOrderList !== undefined) {
  //     setUserList([...qtumOrderList])
  //   } else if (gameId === 'game002' && qrcOrderList !== undefined) {
  //     setUserList([...qrcOrderList])
  //   }
  // }, [gameId, qtumOrderList, qrcOrderList])

  
  return (
    <Container>
      {t('leaderboard')}
      <LeaderboardScore gameId={gameId} winnerScore={score} winnerAddress={winner} />
      <UsersList>
        {users.length === 0 &&
          <ThreeDots
            height="80" 
            width="100" 
            radius="10"
            color="#01FFFF" 
            ariaLabel="three-dots-loading"
            wrapperStyle={{margin: 'auto', justifyContent: 'center'}}
            wrapperClass=""
            visible={true}
          />
        }
        {users.map((user, index) => 
          <LeaderboardTile {...{...user}} key={index}/>
        )}
      </UsersList>
    </Container>
  )
}

export default Leaderboard
