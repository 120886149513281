import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider, useDispatch, useSelector } from 'react-redux'
import Modal from 'react-modal'
import createStore from 'Store'
import { isAppReady } from 'Store/Selectors/App'
import { AppRoute } from 'Constants/Route'
import Home from './Home'
import Game from './Game'
import Faq from './Faq'
import { useEffect } from 'react'
import { AppReady } from 'Store/Actions/App'

const { store } = createStore()

Modal.setAppElement('#root')

const App = () => {
  const appReady = useSelector(isAppReady)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(AppReady())
  }, [])

  if (!appReady) {
    return (
      <div>Loading</div>
    )
  }

  return (
    <Router>
      <Routes>
        <Route path={AppRoute.ROOT}
          element={
            <Home/>
          }
        />
        <Route path={AppRoute.GAME}
          element={
            <Game/>
          }
        />
        <Route path={AppRoute.FAQ}
          element={
            <Faq/>
          }
        />
      </Routes>
    </Router>
  )
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App/>
    </Provider>
  )
}

export default AppWrapper