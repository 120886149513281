import React, { useCallback, useState } from "react";
import { Qnekt_ERC20 } from "Constants/Network";
import { BigNumber, Contract, ethers } from 'ethers'
import { getBigNumberFromString, getPaddedHexStrFromBN, getRPCProvider, getSigner, isAddress } from "Utils/web3Helper";
import QRC20_ABI from "Constants/QRC20_ABI.json";
import { getDataByAddress } from "Utils/apiHelper";
import { LeaderboardUser } from "Types/user";
import { useActiveWeb3React } from "hooks";
import { ArtServiceCheck, SignRequest } from "Store/Actions/App";
import { notifyToast } from "Utils/toast";
import { useDispatch } from "react-redux";

export const useUserInfo = () => {
  const { account, chainId, library } = useActiveWeb3React()
  const [qtumBalance, setQtumBalance] = useState<BigNumber | undefined>()
  const [qrcBalance, setQrcBalance] = useState<BigNumber | undefined>()
  const [signerData, setSignerData] = useState<LeaderboardUser | undefined>()
  const ABI = QRC20_ABI as any
  const dispatch = useDispatch()

  const handleQtumBalance = useCallback(async (account: string) => {
    if (!chainId) return
    try {
      const qtumProvider = getRPCProvider(chainId)
      if (!qtumProvider) return
      const balance = await qtumProvider?.getBalance(account)
      setQtumBalance(balance)
    } catch (err) {
      console.log('[qtum balance sync error]', err)
      setQtumBalance(BigNumber.from(0))
    }
    // console.log('[qtum provider check on handle qtum balance]', qtumProvider)
  }, [chainId])

  const handleQRC20Balance = useCallback(async (account: string) => {
    if (!chainId) return
    // console.log('[qtum provider check on handle qtum balance]', qtumProvider)
    try {
      const qtumProvider = getRPCProvider(chainId)
      if (!qtumProvider) return
      const parsed = isAddress(Qnekt_ERC20)
      if (parsed !== false) {
        const qrcContract = new Contract(parsed, ABI, qtumProvider!)
        const balance = await qrcContract.balanceOf(account)
        setQrcBalance(balance)
      }
    } catch (err) {
      console.log('[qrc balance sync error]', err)
      setQrcBalance(BigNumber.from(0))
    }
  }, [chainId])

  const handleSignerData = useCallback(async (account: string) => {
    if (!chainId) return
    const data = await getDataByAddress(account, chainId)
    const userData = JSON.parse(data!)
    setSignerData({...userData})
  }, [chainId])
  
  const handleGetSignature = useCallback(async () => {
    if (!chainId || !library || !account) return;
    try {
      const d = new Date()
      const timestamp = Math.floor(d.getTime() / 1000)
      const currentTime = getBigNumberFromString(timestamp.toString(), 0)
      const paddedTimeStamp = getPaddedHexStrFromBN(currentTime)
      const hexData = account + paddedTimeStamp.slice(2)
      const signer = getSigner(library, account)
      const signature = await signer.signMessage(ethers.utils.arrayify(hexData))
      dispatch(SignRequest(signature))
    } catch (err) {
      console.log('[sign message error]', err)
      notifyToast({ id: 'sign message', type: 'error', content: `Sign message failed.` })
    }
  }, [account, library, dispatch])

  const handleCheckArtService = useCallback(async (signature) => {
    if (!chainId || !library || !account) return;
    try {
      dispatch(ArtServiceCheck(signature))
    } catch (err) {
      console.log('[sign message error]', err)
      notifyToast({ id: 'sign message', type: 'error', content: `Art service access failed.` })
    }

  }, [account, library, dispatch])
  
  return { qtumBalance, qrcBalance, signerData, handleQtumBalance, handleQRC20Balance, handleSignerData, handleGetSignature, handleCheckArtService }
}