import { createReducer } from "Utils/store"
import { APP_READY, SYNC_BALANCE, SIGN_MESSAGE, ART_SERVICE_ACCESS, ART_ACCESS_TOKEN } from 'Store/Actions/App'

const initialState = {
  isAppReady: false,
  isSync: false,
  signMessage: '',
  artAccessToken: '',
  artServiceAccess: false,
}

export default createReducer(initialState, {
  [APP_READY]: (state: any) => ({...state, isAppReady: true}),
  [SYNC_BALANCE]: (state: any, action: any) => {
    return {
      ...state,
      isSync: action.value
    }
  },
  [SIGN_MESSAGE]: (state: any, action: any) => {
    return {
      ...state,
      signMessage: action.value
    }
  },
  [ART_ACCESS_TOKEN]: (state: any, action: any) => {
    return {
      ...state,
      artAccessToken: action.value
    }
  },
  [ART_SERVICE_ACCESS]: (state: any, action: any) => {
    return {
      ...state,
      artServiceAccess: action.value
    }
  }
})