import React, { ChangeEvent, useCallback, useState } from "react";
import { useActiveWeb3React, useScoring, useUserInfo } from "hooks";
import { getBigNumberFromString, getNumberFromBigNumber, getPaddedHexStrFromBN, getSigner } from "Utils/web3Helper";
import { useGetQRCContract } from "./useContracts";
import { notifyToast } from "Utils/toast";
import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_PRICE } from "Constants/Network";
import { useDispatch } from "react-redux";
import { SignRequest, SyncBalance } from "Store/Actions/App";
import { ethers } from "ethers";
import { art_service_sign } from "Utils/apiHelper";

export interface TargetAccount {
  address: string
  name: string
}


export const useSendCoins = () => {
  const { account, chainId, library } = useActiveWeb3React()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const QRC_Contract = useGetQRCContract(true, false)
  const [targetAccount, setTargetAccount] = useState<TargetAccount | null>()
  const [sendAmount, setSendAmount] = useState<string>('')
  const [sendModalOpen, setSendModalOpen] = useState(false)
  const { handleQTUMScore, handleQRCScore } = useScoring()
  const dispatch = useDispatch()

  const toggleModal = (isOpen: boolean) => () => {
    setSendModalOpen(isOpen)
    if (!isOpen) {
      setSendAmount('')
      setTargetAccount(null)
    }
  }

  const handleOpenModal = useCallback((targetAddress: string, targetName: string) => {
    if (!account) return
    if (account === targetAddress) {
      notifyToast({ id: 'sending error', type: 'error', content: "You can't send to your address." })
      return
    }
    setTargetAccount({ ...targetAccount, address: targetAddress, name: targetName })
    setSendModalOpen(true)
  }, [targetAccount, sendModalOpen])

  const handleSetAmountWithOption = useCallback((amount: string, isMax: boolean = false) => {
    if (isMax) {
      const gasLimit = getBigNumberFromString(DEFAULT_GAS_LIMIT, 0);
      const gasPrice = getBigNumberFromString(DEFAULT_GAS_PRICE, 10)
      const availableMax = getNumberFromBigNumber(getBigNumberFromString(amount).sub(gasLimit.mul(gasPrice))).toString()
      setSendAmount(availableMax)
    } else {
      setSendAmount(amount)
    }
  }, [sendAmount])

  const handleSetAmountManually = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value
    const reg = /\d+?\.?\d*$/
    if (v !== '' && !reg.test(v)) return
    if (v.split('.').length > 2) return
    setSendAmount(e.target.value)
  }, [sendAmount])

  const handleQtumSendTx = useCallback(async () => {
    if (!chainId || !library || !account) return;
    if (isLoading) return
    if (sendAmount === '' || Number(sendAmount) === 0) {
      notifyToast({ id: 'QRC transfer', type: 'error', content: `You should enter valid QTUM amount to send.` })
    }
    try {
      setIsLoading(true)
      const signer = getSigner(library, account)
      const tx = await signer.sendTransaction({
        to: targetAccount?.address,
        value: getBigNumberFromString(sendAmount)
      })
      const txResult = await tx.wait(1)
      if (txResult.status) {
        setIsLoading(false)
        setSendModalOpen(false)
        handleQTUMScore()
        dispatch(SyncBalance(true))
      }
    } catch (err) {
      console.log('[qtum sending error]', err)
      notifyToast({ id: 'QRC transfer', type: 'error', content: `QRC token transferring failed.` })
      setSendModalOpen(false)
    } finally {
      setIsLoading(false)
      setSendAmount('')
      setTargetAccount(null)
      setSendModalOpen(false)
    }
  }, [dispatch, chainId, account, sendAmount, targetAccount])

  const handleQRCSendTx = useCallback(async () => {
    if (!chainId || !account || !QRC_Contract) return
    if (isLoading) return
    if (sendAmount === '' || Number(sendAmount) === 0) {
      notifyToast({ id: 'QRC transfer', type: 'error', content: `You should enter valid QRC amount to send.` })
    }
    try {
      setIsLoading(true)
      const tx = await QRC_Contract.transfer(targetAccount?.address, getBigNumberFromString(sendAmount))
      const receipt = await tx.wait(1)
      if (receipt.status) {
        setSendModalOpen(false)
        setIsLoading(false)
        handleQRCScore()
        dispatch(SyncBalance(true))
      }
    } catch (err) {
      notifyToast({ id: 'QRC transfer', type: 'error', content: `QRC token transferring failed.` })
      setSendModalOpen(false)
    } finally {
      setIsLoading(false)
      setSendAmount('')
      setTargetAccount(null)
      setSendModalOpen(false)
    }
  }, [account, isLoading, QRC_Contract, sendAmount, targetAccount])


  return { isLoading, targetAccount, sendAmount, sendModalOpen, toggleModal, handleOpenModal, handleSetAmountWithOption, handleSetAmountManually, handleQtumSendTx, handleQRCSendTx }
}
