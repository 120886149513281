import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  HeaderWrapper,
  BackButton,
  Description,
  TitleWrapper
} from './GameHeader.components'
import { Game } from 'Types/game'

const GameHeader: React.FC<Game> = (game) => {
  const {
    gameNamespace, assetsFolderName
  } = game
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }

  return (
    <HeaderWrapper>
      <BackButton onClick={handleBack}>{t('back')}</BackButton>
      <Container backgroundImage={require(`../../assets/images/${assetsFolderName}/header.webp`)}>
        <Description style={{ marginTop: '1%' }} dangerouslySetInnerHTML={{ __html: t('description', { ns: gameNamespace }) }}>
          {/* {t('description', { ns: gameNamespace })} */}
        </Description>
        {game.id === 'game001' && <>
          <TitleWrapper dangerouslySetInnerHTML={{ __html: t('title', { ns: "bugReport" }) }} />
          <Description dangerouslySetInnerHTML={{ __html: t('description', { ns: "bugReport" }) }} />
        </>
        }
      </Container>
    </HeaderWrapper>
  )
}

export default GameHeader
