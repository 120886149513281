import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'Components'
import {
  Container,
  Name,
  Testnet,
  Balance,
  SendButton,
} from './ParticipantTile.components'
import { LeaderboardUser, User } from 'Types/user'
import { useUserInfo } from 'hooks'
import { getBalanceNumber, getNumberFromBigNumber, shortenAddress } from 'Utils/web3Helper'
import { toast } from 'react-toastify'
import { BigNumber } from 'ethers'
import { useParams } from 'react-router-dom'
import { useSendCoins } from 'hooks/useSendCoins';
import { useDispatch, useSelector } from 'react-redux'
import { isSync } from 'Store/Selectors/App'
import { SyncBalance } from 'Store/Actions/App'

const ParticipantTile: React.FC<{user: LeaderboardUser; handleOpenModal: (targetAddress: string, targetName: string) => void}> = ({user, handleOpenModal}) => {
  const { t } = useTranslation()
  let { gameId } = useParams()
  const dispatch = useDispatch()
  const { qtumBalance, qrcBalance, handleQtumBalance, handleQRC20Balance } = useUserInfo()
  const isSyncCheck = useSelector(isSync)

  const unsecuredCopyToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
  }

  const userNameCopy = (userAddress: string) => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(userAddress)
    } else {
      unsecuredCopyToClipboard(userAddress)
    }
    toast(`${user.discord_name}'s address coppied!`)
  }

  useEffect(() => {
    if (isSyncCheck) {
      handleQtumBalance(user.hex_address)
      handleQRC20Balance(user.hex_address)
      dispatch(SyncBalance(false))
    }
  }, [dispatch, isSyncCheck])

  useEffect(() => {
    handleQtumBalance(user.hex_address)
    handleQRC20Balance(user.hex_address)
  }, [handleQtumBalance, handleQRC20Balance])
  
  return (
    <Container>
      <Avatar
        url={require(`../../../assets/images/temp/${Math.floor(getNumberFromBigNumber(BigNumber.from(user.hex_address), 47)) % 2 === 0 ? 'avatar01.webp' : 'avatar02.webp'}`)}
        size={146}
        centerAvatar={true}
      />
      <Name onClick={() => userNameCopy(user.hex_address)}>{user.discord_name} <Testnet>({shortenAddress(user.hex_address)})</Testnet></Name>
      {gameId === 'game001' ? (
        <Balance>{qtumBalance !== undefined ? getBalanceNumber(qtumBalance!) : '0.00'}{' QTUM'}</Balance>
      ) : (
        <Balance>{qrcBalance !== undefined ? getBalanceNumber(qrcBalance!) : '0.00'}{' QRC'}</Balance>
      )}
      <SendButton onClick={() => handleOpenModal(user.hex_address, user.discord_name)}>{t('send')}</SendButton>

    </Container>
  )
}

export default ParticipantTile
